import { fetchApi } from "../fetchApi"
import Cookies from "js-cookie"

export type Service =
  | "cactus"
  | "owl"
  | "palm"
  | "payload"
  | "unicorn"
  | "trap"
  | "viewer"

export interface User {
  id: string
  name: string
  role: string
  access: Service[]
  email: string
  createdAt: string
  updatedAt: string
  _strategy: string
}

export interface UserRequest {
  user: User
  collection: string
  token: string
  exp: number
}

export const getToken = async () => {
  const session = Cookies.get("div-token-v3")

  if (!session) {
    return null
  }

  return session
}

export const getUser = async () => {
  const session = Cookies.get("div-token-v3")

  if (!session) {
    return null
  }

  const user = (await fetch(
    `${process.env.NEXT_PUBLIC_PAYLOAD_URL}/api/users/me/`,
    {
      method: "GET",
      headers: {
        Authorization: `JWT ${session}`,
      },
      credentials: "include",
    }
  ).then((req) => req.json())) as UserRequest | null

  if (!user) {
    return null
  }

  return user
}

export const getUserLocal = async () => {
  const session = Cookies.get("div-token-v3")

  if (!session) {
    return null
  }

  const user = await fetchApi<UserRequest | null>("/api/auth/me/", {
    method: "GET",
    headers: {
      Authorization: `JWT ${session}`,
    },
    credentials: "include",
  })

  if (!user) {
    return null
  }

  return user
}

export const remoteLogin = async (email: string, password: string) => {
  const req = (await fetchApi("/api/auth/login/", {
    email,
    password,
  })) as any

  if (req && req.token) {
    Cookies.set("div-token-v3", req.token, { expires: 2 })
    return true
  }

  if (req && req.errors) {
    return req
  }

  return false
}

export const remoteLogout = async () => {
  const req = (await fetchApi("/api/auth/logout/", {})) as any

  if (req) {
    Cookies.remove("div-token-v3", req.token)
    return true
  }

  return false
}

export const remoteForgotPassword = async (email: string) => {
  const req = (await fetchApi("/api/auth/forgot/", {
    email,
  })) as any

  if (req) {
    return true
  }

  return false
}

export const hasAccess = (user: User, service: Service) => {
  return user?.access?.includes(service)
}
