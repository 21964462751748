/**
 * Fetches a data request to an API endpoint
 *
 * @param endpoint - The endpoint you are going to call
 * @param data - Any data you want to pass, like graphQL variables or other parameters
 * @returns The data outputted by that endpoint
 */
export const fetchApi = async <T>(
  endpoint: string,
  data?: object
): Promise<T> => {
  const initBody = {
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
    },
    method: "POST",
  }

  const res = await fetch(endpoint, initBody)

  const json = await res.json()
  if (json.error) {
    const error = new Error(json.error)
    throw error
  }

  return json
}
