"use client"
import { useRef, useState } from "react"
import {
  getUserLocal,
  remoteForgotPassword,
  remoteLogin,
} from "@/lib/app/auth.client"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
// import JSConfetti from "js-confetti"
import Link from "next/link"
import { useSearchParams } from "next/navigation"
// import anime from "animejs"
import * as z from "zod"

const schema = z.object({
  email: z.string().email().min(1, { message: "Required" }),
  password: z.string().min(1, { message: "Required" }),
})

interface FormValues {
  email: string
  password: string
}

export const LoginScreen = () => {
  const [username, setUsername] = useState("")
  const [loading, setLoading] = useState(false)
  const [forgot, setForgot] = useState(false)
  const [success, setSuccess] = useState(false)
  const searchParams = useSearchParams()

  const submitRef = useRef<HTMLButtonElement>(null)
  const submitContainerRef = useRef<HTMLDivElement>(null)
  const emailRef = useRef<HTMLDivElement>(null)
  const emailContainerRef = useRef<HTMLDivElement>(null)
  const passRef = useRef<HTMLDivElement>(null)
  const passContainerRef = useRef<HTMLDivElement>(null)

  // const { active, sawEgg, runawayPoints, incrementPoints } = useEasterEgg({
  //   login: () => {
  //     if (!active || sawEgg) {
  //       return
  //     }

  //     const main = document.getElementById("main-container")
  //     main!.style.background =
  //       "url('/circus_bears.png') no-repeat center center"
  //     main!.style.backgroundSize = "cover"

  //     const gladiator: HTMLAudioElement = document.getElementById(
  //       "gladiator"
  //     ) as HTMLAudioElement
  //     if (!gladiator) {
  //       const audio = document.createElement("audio")
  //       document.body.appendChild(audio)
  //       audio.id = "gladiator"
  //       audio.src = "/entry-of-the-gladiator.mp3"
  //       audio.loop = true
  //     }

  //     const horn: HTMLAudioElement = document.getElementById(
  //       "horn"
  //     ) as HTMLAudioElement
  //     if (!horn) {
  //       const audio = document.createElement("audio")
  //       document.body.appendChild(audio)
  //       audio.id = "horn"
  //       audio.src = "/horn.mp3"
  //       audio.autoplay = false
  //       audio.loop = false
  //       audio.volume = 1
  //     }

  //     const button = submitRef.current
  //     const email = emailRef.current
  //     const pass = passRef.current

  //     const buttonContainer = submitContainerRef.current
  //     const emailContainer = emailContainerRef.current
  //     const passContainer = passContainerRef.current

  //     const buttonBounds = buttonContainer?.getBoundingClientRect()
  //     const emailBounds = emailContainer?.getBoundingClientRect()
  //     const passBounds = passContainer?.getBoundingClientRect()

  //     const RUNAWAY_UNTIL = 8

  //     const randomNum = (min: number, max: number) =>
  //       Math.floor(Math.random() * (max - min) + min)

  //     const animate = (
  //       targets: HTMLElement,
  //       prop: "left" | "top" | "rotate",
  //       pixels: number
  //     ) =>
  //       anime({
  //         targets,
  //         [prop]: `${pixels}${prop === "rotate" ? "deg" : "px"}`,
  //         easing: "easeOutCirc",
  //       })

  //     const runaway = () => {
  //       const containerBounds = buttonContainer?.getBoundingClientRect()
  //       if (horn) {
  //         horn.play()
  //       }

  //       const buttontop = randomNum(
  //         buttonContainer!.offsetHeight / 2,
  //         window.innerHeight - buttonContainer!.offsetHeight
  //       )
  //       const buttonleft = randomNum(
  //         -buttonBounds!.left,
  //         buttonBounds!.right - button!.offsetWidth
  //       )
  //       const emailtop = randomNum(
  //         emailContainer!.offsetHeight / 2,
  //         window.innerHeight - emailContainer!.offsetHeight
  //       )
  //       const emailleft = randomNum(
  //         -emailBounds!.left,
  //         emailBounds!.right - email!.offsetWidth
  //       )
  //       const passtop = randomNum(
  //         passContainer!.offsetHeight / 2,
  //         window.innerHeight - passContainer!.offsetHeight
  //       )
  //       const passleft = randomNum(
  //         -passBounds!.left,
  //         passBounds!.right - pass!.offsetWidth
  //       )

  //       animate(button!, "top", buttontop - containerBounds!.top).play()
  //       animate(button!, "left", buttonleft).play()
  //       animate(button!, "rotate", Math.random() * 360).play()
  //       animate(email!, "top", emailtop - containerBounds!.top).play()
  //       animate(email!, "left", emailleft).play()
  //       animate(email!, "rotate", Math.random() * 360).play()
  //       animate(pass!, "top", passtop - containerBounds!.top).play()
  //       animate(pass!, "left", passleft).play()
  //       animate(pass!, "rotate", Math.random() * 360).play()
  //     }

  //     const reset = () => {
  //       animate(button!, "top", 0).play()
  //       animate(button!, "left", 0).play()
  //       animate(button!, "rotate", 0).play()
  //       animate(email!, "top", 0).play()
  //       animate(email!, "left", 0).play()
  //       animate(email!, "rotate", 0).play()
  //       animate(pass!, "top", 0).play()
  //       animate(pass!, "left", 0).play()
  //       animate(pass!, "rotate", 0).play()
  //     }

  //     const loginButtonMouseEnter = () => {
  //       if (runawayPoints < RUNAWAY_UNTIL) {
  //         incrementPoints(2)
  //         runaway()
  //       } else {
  //         reset()
  //       }
  //     }

  //     const mainContainerMouseEnter = () => {
  //       if (gladiator) {
  //         gladiator.play()
  //       }
  //     }

  //     const loginButtonClick = (e: MouseEvent) => {
  //       if (runawayPoints < RUNAWAY_UNTIL) {
  //         e.preventDefault()
  //         incrementPoints(2)
  //         runaway()
  //       } else {
  //         const jsConfetti = new JSConfetti()
  //         jsConfetti.addConfetti()
  //       }
  //     }

  //     const loginButton = document.getElementById("login-button")
  //     const mainContainer = document.getElementById("main-container")
  //     if (loginButton) loginButton.onclick = loginButtonClick
  //     if (loginButton) loginButton.onmouseenter = loginButtonMouseEnter
  //     if (mainContainer) mainContainer.onmouseover = mainContainerMouseEnter
  //   },
  // })

  const { register, handleSubmit, setError, formState } = useForm<FormValues>({
    resolver: zodResolver(schema),
  })

  const signIn = async (data: FormValues) => {
    if (!data.email || !data.password || loading) {
      return
    }
    setLoading(true)

    const login = await remoteLogin(data.email, data.password)

    if (typeof login === "object" && login.errors) {
      setError("password", {
        type: "invalid",
        message: login.errors[0].message,
      })
      setLoading(false)
      return false
    }

    const redirectTo =
      searchParams && searchParams.has("redirect")
        ? (searchParams.get("redirect") as string)
        : "/"

    if (login) {
      setSuccess(true)

      window.location.href = redirectTo
    } else {
      setLoading(false)
      return false
    }
  }

  const recover = async () => {
    if (!username || loading) {
      return
    }
    setLoading(true)

    const recovering = await remoteForgotPassword(username)
    if (recovering) {
      setForgot(false)
      setLoading(false)
      setSuccess(true)
    } else {
      setLoading(false)
    }
  }

  return (
    <div
      id="main-container"
      className="flex flex-col items-center justify-center h-[95vh] min-h-full py-12 sm:px-6 lg:px-8 pb-4"
      style={{
        background: "url('/background.jpg') no-repeat center center",
        backgroundSize: "cover",
      }}
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="w-auto  mx-auto text-[180px] text-center">🚀</div>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        {success ? (
          <div className="px-4 py-8 space-y-6 bg-white shadow sm:rounded-lg sm:px-10">
            <p className="text-xl font-bold text-center">Success!</p>
            <p className="text-sm text-center underline">
              <Link href="/">Click here if you are not redirected</Link>
            </p>
          </div>
        ) : forgot ? (
          <form className="px-4 py-8 space-y-6 bg-white shadow sm:rounded-lg sm:px-10">
            <input
              type="text"
              className="flex justify-center w-full px-3 py-2 text-sm text-black rounded-md shadow-sm bg-gray-50 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onChange={(e) => setUsername(e.target.value)}
              placeholder="email@div.haus"
            />
            <button
              type="button"
              onClick={() => recover()}
              disabled={success}
              className="flex justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {loading ? "Loading..." : "make me remember"}
            </button>
          </form>
        ) : (
          <form
            onSubmit={handleSubmit((data) => signIn(data))}
            className="px-4 pt-2 pb-8 space-y-6 bg-white shadow sm:rounded-lg sm:px-10"
          >
            <h2 className="mt-6 text-2xl font-bold tracking-tight text-center text-gray-900">
              Welcome to Mission Control
            </h2>
            <div className="relative h-[36px]" ref={emailContainerRef}>
              <div className="absolute w-full" ref={emailRef}>
                <input
                  type="text"
                  placeholder="email@div.haus"
                  disabled={loading}
                  autoComplete="email"
                  {...register("email")}
                  className="flex justify-center w-full px-3 py-2 text-sm text-black rounded-md shadow-sm bg-gray-50 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                />
              </div>
            </div>

            <div className="relative h-[36px]" ref={passContainerRef}>
              <div className="absolute w-full" ref={passRef}>
                <input
                  type="password"
                  placeholder="password"
                  {...register("password")}
                  className="absolute flex justify-center w-full px-3 py-2 text-sm text-black rounded-md shadow-sm bg-gray-50 hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                />
              </div>
            </div>

            {formState.errors.password && (
              <p className="text-sm text-center text-red-500">
                {formState.errors.password.message}
              </p>
            )}

            <div className="relative w-full h-[36px]" ref={submitContainerRef}>
              {" "}
              <button
                id="login-button"
                ref={submitRef}
                type="submit"
                disabled={loading}
                className="absolute justify-center w-full px-3 py-2 text-sm font-semibold text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                {loading ? "Loading..." : "Sign In"}
              </button>
            </div>
          </form>
        )}
        {!success && (
          <button
            className="w-full my-2 text-center text-gray-100 underline hover:opacity-75"
            onClick={() => setForgot(!forgot)}
            disabled={loading}
          >
            {forgot
              ? "Wait... I remember now!"
              : "Click here if you forgot your password"}
          </button>
        )}
      </div>
    </div>
  )
}

export default LoginScreen
